@import 'https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css';
@import './../node_modules/lightgallery/css/lightgallery.css';
@import './../node_modules/lightgallery/css/lg-zoom.css';
@import './../node_modules/lightgallery/css/lg-thumbnail.css';
@import url('https://fonts.cdnfonts.com/css/pinky-cupid');

h1 {
	// font-family: 'Inter', sans-serif;
	font-family: 'Pink Cupid', sans-serif;

	font-weight: bold;
}

h2 {
	font-family: 'Pink Cupid', sans-serif;
}

p {
	font-family: 'Montserrat', sans-serif;
}

#initial {
	transition: transform 1s cubic-bezier(0.68, -0.55, 0.27, 1.55),
		opacity 0.5s ease-in-out, max-height 2s ease-in-out;
	overflow: hidden;
	max-height: 1000px;
}

.slideUp {
	transform: translateY(-100%);
	opacity: 0;
	max-height: 0;
}

.container__primary {
	max-width: 1344px;
	margin: 0 auto;

	@media only screen and (max-width: 1407px) and (min-width: 1216px) {
		max-width: 85%;
	}

	@media only screen and (max-width: 1215px) {
		max-width: 90%;
	}

	@media only screen and (max-width: 768px) {
		max-width: 100%;
	}
}

.section-container {
	position: relative;
	display: flex;
	align-items: center;
}

.is-outfit {
	font-family: 'Outfit', sans-serif !important;
	font-weight: 600;
	color: #194b77;
}

.is-hover-btn {
	&:hover {
		filter: brightness(0.9);
	}
}

.is-desktop {
	display: block;

	@media only screen and (max-width: 1023px) {
		display: none !important;
	}
}

.is-mobile {
	display: block;

	@media only screen and (min-width: 1024px) {
		display: none !important;
	}
}

/******** cover sectionnn  *****/

.cover-container {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	justify-content: center;
	align-items: top;

	img {
		object-fit: cover;
	}

	@media only screen and (max-width: 1024px) {
		display: flex;
		flex-direction: column-reverse;
	}
}

.corona {
	width: 134px;

	@media only screen and (max-width: 1024px) {
		top: -193% !important;
		width: 75px;
	}
}

.profile-picture-dsktp {
	display: block;

	@media only screen and (max-width: 1024px) {
		display: none;
	}
}

.profile-picture-mobile {
	display: block;

	@media only screen and (min-width: 1023px) {
		display: none;
	}
}

.vector-tl {
	width: 300px;

	@media only screen and (max-width: 1200px) {
		width: 50%;
	}
}

.vector-tr {
	width: 187px;

	@media only screen and (max-width: 1200px) {
		width: 60px;
	}
}

.vector-bl {
	width: 11vw;

	@media only screen and (max-width: 1200px) {
		width: 65px;
	}
}

.vector-br {
	width: 13vw;

	@media only screen and (max-width: 1200px) {
		width: 76px;
	}
}

.cover-section-info {
	flex-direction: column;

	@media only screen and (max-width: 1024px) {
		// margin-bottom: -22%;
	}
}

.title {
	font-size: 63px;
	font-weight: 400;
	line-height: 70px;
	letter-spacing: 0em;
	text-align: center;

	@media only screen and (min-width: 1200px) {
		font-size: 119px;
		line-height: 124px;
	}
}

/********* INITIAL SECTION ********/

.initial-content {
	display: grid;
	// grid-template-columns: repeat(2, 1fr);
	justify-content: center;
	align-items: center;
	height: 100%;

	@media only screen and (max-width: 1024px) {
		grid-template-columns: unset;
		display: flex;
		flex-direction: column;
		gap: 23px;

	}
}

/******************** My parents section ****************************/
.my-parents-container {
	display: grid;
	grid-template-columns: 1fr 1fr;

	@media only screen and (width < 880px) {
		display: grid;
		grid-template-columns: 1fr;
	}

	.left-side {
		display: flex;
		flex-direction: column;

		.my-parents-text {

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			p {
				width: 60%;
				margin-bottom: 36px;
			}
		}

		.my-parents-sm-photos {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;

			img {
				width: 55%;
				margin-top: 0;
			}

			@media only screen and (width < 880px) {
				display: none;
			}
		}
	}

	.right-side {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		img {
			width: 100%;
		}

		.img-text {
			display: none;

			@media only screen and (width < 880px) {
				display: inline;
			}
		}

		.only-img {
			@media only screen and (width < 880px) {
				display: none;
			}
		}
	}
}

/******************** One img section ****************************/
.one-img-container {
	position: relative;
	display: flex;
	justify-content: center;
	margin-top: 0px;

	img {
		margin: auto;

		@media only screen and (width < 550px) {
			margin-left: 0;
			max-width: 100%;
		}
	}
}

/******************** Thanks section ****************************/
.thanks-container {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	.thanks-text {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 15px;

		svg {
			@media only screen and (max-width: 1024px) {
				width: 45px;
			}
		}
	}

	h2 {
		margin: 0px;
		text-align: center;
		font-family: 'Pinky Cupid', sans-serif;
		color: #ff5e7f;
		font-weight: 400;
		font-size: 6em;

		@media only screen and (max-width: 1024px) {
			font-size: 2rem;
		}
	}
}

.initial-content {
	// display: grid;
	// grid-template-columns: repeat(2, 1fr);
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	position: relative;


	@media only screen and (max-width: 1023px) {
		grid-template-columns: unset;
		display: flex;
		flex-direction: column;
		gap: 23px;
		// grid-template-rows: 1fr 2fr 0.5fr;
		justify-content: start;
	}
}

.text-message {
	margin-left: 50px;

	@media only screen and (max-width: 1024px) {
		margin-left: 0;
	}
}

.subtitle {
	font-size: 48px;
	font-weight: 400;
	line-height: 70px;
	letter-spacing: 0em;
	text-align: center;
	margin-top: -10px;
	margin-left: 0;

	@media only screen and (min-width: 1023px) {
		font-size: 80px;
		line-height: 124px;
		margin-top: -45px;
		margin-left: 30%;
	}
}

/******* DATE SECTION *******/
.content-date {
	@media (max-width: 769px) {
		height: 86% !important;
	}
}

.date-info-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	justify-content: center;
	align-items: center;
	height: auto;

	@media only screen and (max-width: 769px) {
		grid-template-columns: unset;
		grid-template-rows: 1fr 1fr;

		display: flex;
		flex-direction: column;
		gap: 20px;
		height: auto;
	}

	.map-img {
		width: 150px;

		@media only screen and (max-width: 1023px) {
			width: 81px;


		}
	}

	#openModalMap {
		@media only screen and (max-width: 992px) {
			height: 50px !important;
			width: 196px !important;
		}
	}

	.content-location {
		@media (max-width: 1024px) {
			gap: 8px !important;
		}
	}
}

.text-h3 {
	margin: 0px;
	text-align: center;
	font-family: 'Pinky Cupid', sans-serif;
	color: #ff5e7f;
	font-weight: 400;
	font-size: 6em;

	@media only screen and (max-width: 1024px) {
		font-size: 3rem;
	}
}

/***** COUNTDOWN *******/
.count-down {
	max-width: 436px;

	@media only screen and (max-width: 600px) {
		max-width: 100%;
		width: 100%;
	}
}

.circle {
	width: 45px;
	height: 45px;
	border-radius: 50%;

	@media only screen and (min-width: 1024px) {
		width: 79px;
		height: 79px;
	}
}

.text-countdown {
	width: 400px;

	@media only screen and (max-width: 1024px) {
		width: 300px;
	}
}

.content-btns {
	gap: 53px;

	@media only screen and (max-width: 1024px) {
		gap: 20px;
	}
}

.text-circle {
	font-size: 30px !important;

	@media only screen and (max-width: 1024px) {
		font-size: 18px !important;
	}
}


.sun {
	@media (width< 600px) {
		width: 100px;
	}
}

.fernanda {
	@media (width< 600px) {
		margin-top: 25px;
		width: 100%;
	}
}

.invitacion_primary {
	@media (width< 1200px) {
		// background-color: #FEF5FF;
	}

}

.container_vector {
	@media (width< 1200px) {
		.vector-tl {

			display: none;
		}
	}
}

.invitacion {
	@media (width< 1200px) {
		display: none !important;
	}
}

.dedicatoria {
	padding: 15px;
}

.parents {
	@media (width< 1200px) {
		flex-direction: column;
	}
}

.elements {
	@media (width< 1200px) {
		flex-direction: column;
	}
}

.only-time {
	@media (width< 1200px) {
		width: 60%;
	}
}

.sun-count {
	@media (width< 1200px) {
		display: none;
	}
}

.vector_count {
	@media (width< 1200px) {

		height: 100%;
	}
}

#btnMap {

	@media (width< 1200px) {

		width: 50% !important;
		height: 40px !important;
	}
}

.content_map {

	// @media (width< 1200px) {
	// 	height: 75vh !important;
	// 	min-height: 75vh !important;

	// }
}

.last_content {
	@media (width< 1200px) {
		padding: 0 !important;

	}
}

.map-img {
	max-width: 800px;

	@media only screen and (max-width: 1023px) {
		width: 100%;


	}
}